import React from 'react'
import Layout from '../components/layout/layout'
import Footer from '../components/layout/footer'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

export default function ErrorPage() {
  return (
    <Layout>
      <GatsbySeo
        titleTemplate='404 | %s'
      />
      <div className="main-container">
        <header className="text-white">
          <div className="d-flex justify-content-center sm-adjust page-not-found">
            <h1>Oops! Not this way [404]</h1>
          </div>
          <div className="d-flex justify-content-center">
            <p>Page not found or insufficient permissions.</p>
          </div>
        </header>
        <Footer />
      </div>
    </Layout>
  )
}
